import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from './App';
import Menu from './Menu';
import TargetSparring from './TargetSparring';
import AllCards from './AllCards';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
    {
      index: true,
      element: <Menu />,
    },
    {
      path: "targetsparring/",
      element: <TargetSparring />,
    },
    {
      path: "allcards/",
      element: <AllCards />,
    },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
 <RouterProvider router={router} />
);