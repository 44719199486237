const cards = [
    {
        title: 'Bauchweh',
        image: 'Bauchweh.jpg',
        subtitle: 'Target Card',
        text: 'Triff deinen Gegner in einer Runde mit der Faust, mit dem Fuß und mit dem Knie in den Bauch.',
        slogan: 'Klingt einfach, oder?'
    },
    {
        title: 'Punching Ball',
        image: 'PunchingBall.jpg',
        subtitle: 'Target Card',
        text: 'Lande 2 Kopftreffer direkt hintereinander',
        slogan: 'Spürst du die Federung?'
    },
    {
        title: 'Bill Wallace',
        image: 'BillWallace.jpg',
        subtitle: 'Target Card',
        text: 'Kick mit dem selben Bein zwei mal hintereinander und lande mit dem zweiten Kick einen Kopftreffer',
        slogan: 'Hoch mit den Beinchen!'
    },
    {
        title: 'Kuschelzeit',
        image: 'Kuschelzeit.jpg',
        subtitle: 'Target Card',
        text: 'Erreiche 3 verschiedene Haltepositionen am Boden (Top-, Side-, Back- Control) und halte sie für je 3 Sekunden',
        slogan: 'Halten ist besser als garnichts'
    },
    {
        title: 'Choke It!',
        image: '',
        subtitle: 'Target Card',
        text: 'Zwinge deinen Gegner mit einem Würgegriff zur Aufgabe',
        slogan: 'Siehst du die Lila Farbe im Gesicht? Nein? Dann hast du wohl was falsch gemacht!'
    },
    {
        title: 'Crack It!',
        image: '',
        subtitle: 'Target Card',
        text: 'Zwinge deinen Gegner mit einem Arm- oder Schulterheben zur Aufgabe',
        slogan: 'Keine Arme, keine Kekse...'
    },
    {
        title: 'Attack the Leg!',
        image: '',
        subtitle: 'Target Card',
        text: 'Zwinge deinen gegner mit einem Bein- oder Kniehebel zur Aufgabe',
        slogan: 'Der läuft nicht mehr weg!'
    },
    {
        title: 'Prison Rules!',
        image: '',
        subtitle: 'Target Card',
        text: 'Dein Ziel ist eine Knasttechnik! Dazu zählt alles, was im BJJ als „unsauber“ gilt. Wristlock, Neck Crank, Toe Hold, Groin Stretch ',
        slogan: 'Scheiß egal ob du gewinnst… Hauptsache dein Gegner erinnert sich an den Kampf!'
    },
]

export default cards;