import React, {useState} from 'react';
import './TargetSparring.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import cards from './assets/cards';

import TargetCard from './components/TargetCard';

function getRandomCard() {
  let rnd = Math.floor(Math.random() * cards.length) + 0;
  let card = cards[rnd];
  return <TargetCard flipped={true} card={card}/>
}

function createPlayer(playerCount) {
  let player = [];
  for (let i = 0; i < playerCount; i++) {
    player.push(
      <Col md={3}>
        <div>
          Player {i+1}
        </div>
        <div>
          {getRandomCard()}
        </div>
        <hr/>
      </Col>
    )
  }
  return player;
}

function TargetSparring() {
  const [player, setPlayer] = useState()

  return (
    <Container className="TargetSparring">
        <FormGroup>
          <Label for="exampleNumber">
            Wie viele Spieler?
          </Label>
          <Input
            id="exampleNumber"
            name="number"
            placeholder="Anzahl Spieler"
            type="number"
            min={ 0 } 
	          max={ 100 } 
            onChange={(e) => setPlayer(createPlayer(e.currentTarget.value))}
          />
        </FormGroup>
        <Row>
          {player}
        </Row>        
    </Container>
  );
}

export default TargetSparring;
