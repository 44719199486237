import './AllCards.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Button } from 'reactstrap';
import cards from './assets/cards';

import TargetCard from './components/TargetCard';

function TargetSparring() {
  let allCards = [];

  console.log('allcards:', cards)

  cards.forEach((card) => {
    console.log('card:', card)
    allCards.push(<Col md={3}><TargetCard flipped={false} card={card}/></Col>);
  })

  return (
    <Container className="TargetSparring">
      <Row>
        {allCards}
      </Row>      
    </Container>
  );
}

export default TargetSparring;
