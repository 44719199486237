import './Menu.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Button } from 'reactstrap';

function Menu(props) {
  return (
    <Container className="Menu">
      <Button onClick={() => window.location.href='targetsparring'}>
        Target Sparring
      </Button>

      <Button disabled>
        Szenarien Training
      </Button>

      <Button onClick={() => window.location.href='allcards'}>
        Alle Karten
      </Button>

      <Button disabled>
        Hilfe
      </Button>
    </Container>
  );
}

export default Menu;
