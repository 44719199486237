import React, {createRef} from 'react';
import './TargetCard.scss';
import 'bootstrap/dist/css/bootstrap.css';
import ReactCardFlip from 'react-card-flip';
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';

class TargetCard extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isFlipped: props.flipped === undefined ? true : props.flipped
    };
    this.handleClick = this.handleClick.bind(this);

    console.log(props);
  }

  CardBodyRef = createRef();

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
    console.log(this.CardBodyRef)
  }

  render() {
    let imageUrl = '';
    try {
      imageUrl = require('../assets/images/' + this.props.card.image);
    } catch (e) {
      imageUrl = 'https://picsum.photos/300/200'
    }

    return (
      <ReactCardFlip className={'FlipCard'} isFlipped={this.state.isFlipped} flipDirection="horizontal">
        <Card
          className={'FrontSide'}
          onClick={this.handleClick}
        >
          
          <CardBody>
            <div className='card-ref' ref={this.CardBodyRef}>
              <CardTitle tag="h5">
                <div style={{fontSize: this.CardBodyRef.current && this.CardBodyRef.current.children[0].clientHeight*0.9}}>
                  {this.props.card.title}
                </div>                
              </CardTitle>            

              <div
                className='card-image'
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }} //
              ></div>

              <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
              >
                <div style={{fontSize: this.CardBodyRef.current && this.CardBodyRef.current.children[2].clientHeight*0.7}}>
                  {this.props.card.subtitle}
                </div>
              </CardSubtitle>

              <CardText>
                <div style={{fontSize: this.CardBodyRef.current && this.CardBodyRef.current.children[3].clientHeight/15}}>
                  {this.props.card.text}
                  <hr/>
                  <i>{this.props.card.slogan}</i>
                </div>
              </CardText>
            </div>
          </CardBody>
        </Card>

        <Card
          className={'BackSide'}
          onClick={this.handleClick}         
        >
        </Card>
      </ReactCardFlip>
    )
  }
}

function FrontSide() {
  return (
    <Card
        style={{
            width: '18rem'
        }}
        >
        <img
            alt="Sample"
            src="https://picsum.photos/300/200"
        />
        <CardBody>
            <CardTitle tag="h5">
              Card title
            </CardTitle>

            <CardSubtitle
              className="mb-2 text-muted"
              tag="h6"
            >
              Card subtitle
            </CardSubtitle>

            <CardText>
              Some quick example text to build on the card title and make up the bulk of the card‘s content.
            </CardText>
        </CardBody>
    </Card>
  );
}

function BackSide() {
  return (
    <Card
        style={{
            width: '18rem'
        }}
        >
        <img
            alt="Sample"
            src="https://picsum.photos/300/200"
        />
        <CardBody>
            <CardTitle tag="h5">
              Lalala
            </CardTitle>

            <CardSubtitle
              className="mb-2 text-muted"
              tag="h6"
            >
              Card subtitle
            </CardSubtitle>

            <CardText>
              Some quick example text to build on the card title and make up the bulk of the card‘s content.
            </CardText>
        </CardBody>
    </Card>
  );
}

export default TargetCard;
