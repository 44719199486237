import React from 'react';
import { Outlet } from 'react-router-dom'
import { Button } from 'reactstrap'
// import Heading from './components/Heading'
// import Footing from './components/Footing'

export default function App() {
  return (
    <div>
      <div>
        <h1>Target Sparring!</h1>
        <hr/>
      </div>
      <Outlet />
      {/* <Footing /> */}
    </div>
  );
}